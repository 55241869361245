<template>
    <div class="aplayz_player_left_container">
        <input ref="volumeRange2" id="volumeRange2" class="cursor" type="range" min="0" max="1" step="0.1" hidden />
        <ul class="aplayz_player_left_ul">
            <li class="aplayz_player_li left" v-if="musicinfo">
                <img
                    :src="musicinfo.albumImg.includes('emptyImg') ? getPlaceHolder(musicinfo.musicId) : musicinfo.albumImg"
                    :alt="musicinfo.musicNm"
                    class="aplayz_player_album_img"
                />
                <div class="song_title_wrap">
                    <h1 class="title_lg song_title" v-rolling="{ str: musicinfo.musicNm, speed: 20 }"></h1>
                </div>
                <div class="song_artist_wrap">
                    <h3 class="title_sm song_artist" v-rolling="{ str: musicinfo.artist, speed: 20 }"></h3>
                </div>
                <ul class="aplayz_player_info_group">
                    <li class="aplayz_player_info_item">
                        <img
                            @click="$emit('click-like')"
                            class="icon1 cursor"
                            :src="islike ? '/media/img/like_icon_active.png' : '/media/img/like_icon_off.png'"
                            alt="좋아요"
                        />
                    </li>
                    <li class="aplayz_player_info_item">
                        <img
                            @click="$emit('click-hate')"
                            class="icon1 cursor"
                            :src="isunlike ? '/media/img/hate_icon_active.png' : '/media/img/hate_icon_off.png'"
                            alt="싫어요"
                        />
                    </li>
                    <li class="aplayz_player_info_item">
                        <img
                            @click="$emit('click-comment')"
                            class="icon2 cursor"
                            src="/media/img/icon_pc_comment_30.png"
                            alt="한줄평"
                        />
                    </li>
                    <li class="aplayz_player_info_item">
                        <img
                            @click="$emit('click-report')"
                            class="icon2 cursor"
                            src="/media/img/icon_pc_report_30.png"
                            alt="신고하기"
                        />
                    </li>
                </ul>
                <ul class="aplayz_player_control_group">
                    <li class="aplayz_player_control_item1 cursor">
                        <img
                            @click="playControl"
                            :src="isplaymusic ? '/media/img/webCastPlayer/icon_pause.png' : '/media/img/webCastPlayer/icon_play.png'"
                            alt="icon_play"
                            class="music_control_btn"
                            :class="isplaymusic ? 'pause' : 'play'"
                        />
                    </li>
                    <li class="aplayz_player_control_item2">
                        <img
                            @click="getMute ? initVolume($store.getters['player/getVolume']) : initVolume(0)"
                            :src="getMute ? '/media/img/ces/volume_off.png' : '/media/img/ces/volume_up.png'"
                            alt="volume_up"
                            class="volume_img cursor"
                        />
                        <input id="volumeRange" class="cursor" type="range" min="0" max="1" step="0.1" />
                    </li>
                </ul>
            </li>
            <li class="aplayz_player_li right">
                <div class="right_top">
                    <h2 class="title_lg right_top_title">마이</h2>
                    <component v-if="usertype !== -1" :is="getcomponentname" v-bind="{ ...getprops }" />
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import rolling from '@/utils/directive/rolling';
import { USER_TYPE } from '@/assets/js/usertype';
export default {
  name: 'MyPlayerLeft',
  emits: ['setmusicinfointerval', 'click-like', 'click-hate', 'click-comment', 'click-report', 'playcontrol'],
  props: {
    musicinfo: {
      type: Object,
      required: false,
      default: () => {
        return {
          albumImg: '',
          musicNm: '',
          artist: '',
          musicId: ''
        };
      }
    },
    isplaymusic: {
      type: Boolean,
      required: false,
      default: false
    },
    isplaybrandmusic: {
      type: Boolean,
      required: false,
      default: false
    },
    islike: {
      type: Boolean,
      required: false,
      default: false
    },
    isunlike: {
      type: Boolean,
      required: false,
      default: false
    },
    spacepayat: {
      type: String,
      required: false,
      default: null
    },
    isactivechannel: {
      type: Boolean,
      required: false,
      default: false
    },
    activetab: {
      type: String,
      required: false,
      default: null
    },
    mychannelinfo: {
      type: Object,
      requried: false,
      default: () => {
        return {
          brandId: '',
          brandImg: '',
          channelId: '',
          channelType: '',
          domesticRate: '0',
          genre: '',
          imgUrl: '',
          period: '',
          playerinfofn: () => {},
          sector: '',
          spaceId: '',
          spaceImg: '',
          spaceNm: '',
          spaceType: '',
          src: ''
        };
      }
    },
    clickchannel: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    },
    usertype: {
      type: Number,
      required: false,
      default: 2
    },
    setmanagebookmark: {
      type: Function,
      required: false,
      default: () => {
        return () => {};
      }
    },
    isFreeMusicPlaying: {
      type: Boolean,
      required: false,
      default: false
    },
    freePlayerImg: {
      type: String,
      required: false,
      default: ''
    }
  },
  directives: {
    rolling
  },

  components: {
    'nonuser-space': () => import(`@/components/player/NonUserSpace.vue`),
    'myspace-info': () => import(`@/components/player/MySpaceInfo.vue`),
    'nonhas-space': () => import(`@/components/player/NonHasSpace.vue`)
  },
  data () {
    return {
      inputRangeEle: null,
      musicId: this.musicinfo.musicId,
      placeHolder: ''
    };
  },
  updated () {
    const inputRange = document.querySelector('#volumeRange');
    if (inputRange && !this.inputRangeEle) {
      this.inputRangeEle = inputRange;
      inputRange.addEventListener('input', this.volumeInput);
      if (!this.$store.getters['player/getIsMute']) {
        inputRange.value = this.$store.getters['player/getVolume'];
        this.$VideoPlayer.setVolume(this.$store.getters['player/getVolume']);
        this.$BrandPlayer.setBrandVideoVolume(this.$store.getters['player/getVolume']);
        this.setBackground(inputRange, this.$store.getters['player/getVolume']);
      } else {
        this.$VideoPlayer.setVolume(0);
        this.$BrandPlayer.setBrandVideoVolume(0);
        this.setBackground(inputRange, 0);
      }
    }
  },
  methods: {
    volumeInput (event) {
      const brandVideo = document.querySelector('#brand_video');
      // eslint-disable-next-line camelcase
      const gradient_value = 100 / event.target.attributes.max.value;
      // eslint-disable-next-line camelcase
      const volumeValue = parseInt(gradient_value * event.target.value);
      if (volumeValue === 0) {
        this.$store.commit('player/setIsMute', true);
      } else {
        this.$store.commit('player/setIsMute', false);
      }
      if (brandVideo.paused) {
        this.$VideoPlayer.setVolume(event.target.value);
      }

      this.$BrandPlayer.setBrandVideoVolume(event.target.value);
      if (Number(event.target.value) !== 0) {
        this.$store.commit('player/setVolume', Number(event.target.value));
      }
      event.target.style.background =
        'linear-gradient(to right, #fff 0%, #fff ' +
        volumeValue +
        '%, hsla(0,0%,100%,.16) ' +
        volumeValue +
        '%, hsla(0,0%,100%,.16) 100%)';
      this.$gatag.event('click_volume', {
        space_id: this.$store.state.spaceId,
        space_nm: this.$store.state.spaceNm,
        channel_id: this.$store.state.channelId
      });
    },
    setBackground (ele, value = 0.1) {
      const standard = value;
      ele.value = value;
      // eslint-disable-next-line camelcase
      const gradient_value = 100 / ele.attributes.max.value;
      ele.style.background = `linear-gradient(to right, #fff 0%, #fff ${
        // eslint-disable-next-line camelcase
        gradient_value * standard
        // eslint-disable-next-line camelcase
      }%, hsla(0,0%,100%,.16)  ${gradient_value * standard}%, hsla(0,0%,100%,.16) 100%)`;
    },
    initVolume (value = 0.1) {
      const brandVideo = document.querySelector('#brand_video');

      this.$store.commit('player/setIsMute', !this.$store.getters['player/getIsMute']);

      this.setBackground(document.querySelector('#volumeRange'), value);
      if (brandVideo.paused) {
        this.$VideoPlayer.setVolume(value);
      }
      this.$BrandPlayer.setBrandVideoVolume(value);

      if (value === 0) {
        this.$gatag.event('click_mute', {
          space_id: this.$store.state.spaceId,
          space_nm: this.$store.state.spaceNm,
          channel_id: this.$store.state.channelId
        });
      }
    },
    playControl () {
      this.$emit('playcontrol');
    },
    getPlaceHolder (musicId) {
      if (this.placeHolder === '' || this.musicId !== musicId) {
        this.placeHolder = this.musicinfo.albumImg;
      }
      return this.placeHolder;
    }
  },
  computed: {
    getMute () {
      return this.$store.getters['player/getIsMute'];
    },
    getcomponentname () {
      if (this.usertype === USER_TYPE.SUBSCRIBE || this.usertype === USER_TYPE.FREE_HAS_SPACE) {
        return 'myspace-info';
      } else if (this.usertype === USER_TYPE.FREE_NO_SPACE) {
        //
        return 'nonhas-space';
      }
      return 'nonuser-space';
    },
    getprops () {
      const comp = this.getcomponentname;
      if (comp === 'myspace-info') {
        return {
          isplay: this.isplaymusic,
          isactivechannel: this.isactivechannel,
          activetab: this.activetab,
          mychannelinfo: this.mychannelinfo,
          clickchannel: this.clickchannel
        };
      } else if (comp === 'nonhas-space') {
        return {
          isplay: this.isplaymusic,
          activetab: this.activetab,
          clickchannel: this.clickchannel,
          setmanagebookmark: this.setmanagebookmark
        };
      }
      return {};
    }
  }
};
</script>
<style scoped src="@/assets/css/player/myplayerleft.css"></style>
